import React from 'react';
import Layout from '../../components/layout'
import { Helmet } from 'react-helmet';

import BroadcasterSectionOne from '../../components/bio/GradiantImage';
import SectionOneBackground from '../../images/broadcaster-bg.png';
import SectionOneMobileBackground from '../../images/mobile/Broadcaster.png';

import BroadcasterSectionTwo from '../../components/bio/ImageLeftWhiteBGContainer';
import SectionTwoImage from '../../images/broadcaster_section2.png';

import BroadcasterSectionThree from '../../components/bio/SplitImageOrangeBG';
import SectionThreeLeftImage from '../../images/broadcaster_sec3_1_2.png';
import SectionThreeRightImage from '../../images/broadcaster_sec3_2_2.png';

import BroadcasterSectionFour from '../../components/bio/BottomSectionContainer';
import SectionFourImage from '../../images/broadcaster_sec4.png';


const Broadcaster = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Armstrong's life and legacy: Broadcaster</title>
        <script>
        {`
          (function (s, e, n, d, er) {
            s['Sender'] = er;
            s[er] = s[er] || function () {
              (s[er].q = s[er].q || []).push(arguments)
            }, s[er].l = 1 * new Date();
            var a = e.createElement(n),
                m = e.getElementsByTagName(n)[0];
            a.async = 1;
            a.src = d;
            m.parentNode.insertBefore(a, m)
          })(window, document, 'script', 'https://cdn.sender.net/accounts_resources/universal.js', 'sender');
          sender('a78ebf1b330821')
        `}
      </script>
      </Helmet>
      <Layout stacked={true}>
        <BroadcasterSectionOne
          title={'Broadcaster'}
          text={'Bill Armstrong got his first job in radio as an 11-year-old weekend DJ and he worked at several stations before graduating from high school. He met Todd Storz, the father of “Top 40” radio, who hired Armstrong – America’s first teenage Top 40 DJ – at stations in Omaha, New Orleans, and Minneapolis. He was billed as “Bill Armstrong, The All-American Disc Jockey.” He took classes at Tulane and the University of Minnesota, but it was the broadcasting business that held his interest.'}
          background={SectionOneBackground}
          mobileBackground={SectionOneMobileBackground}
        />
        <BroadcasterSectionTwo
          image={SectionTwoImage}
          captionBlack={'Armstrong in the control booth at '}
          captionOrange={'KOSI'}
          text={`Always dreaming of his own station, in 1959 he bought KOSI in Aurora, at the age of 22. He
          owned it for 25 years, added the FM signal, the original AM station becoming KEZW, and
          became a highly successful broadcaster. He soon became a well-known personality in Denver,
          was President of Armstrong Broadcasting Company and Ambassador Media Corporation. He
          also owned a weekly newspaper, and later a major daily, the Colorado Springs Sun, along with
          three television stations in Idaho and Wyoming. He served as a director and Vice President of the
          Associated Press Broadcasters Association, and was a featured speaker at the National
          Broadcasters Association.`}
        />
        <BroadcasterSectionThree
          leftImage={SectionThreeLeftImage}
          rightImage={SectionThreeRightImage}
          leftCaptionWhite={`"Bill Armstrong, the All-American DJ" WDGY, `}
          leftCaptionBlack={'1957'}
          rightCaptionWhite={'Armstrong gives listeners a tour at '}
          rightCaptionBlack={'KOSI'}
        />
        <BroadcasterSectionFour
          image={SectionFourImage}
          blackCaption={`Armstrong and the Board of Helmerich and Payne, the world's largest on-shore drilling company`}
          blackLink={'Click to next page: '}
          orangeLink={'Businessman'}
          route="/bio/businessman"
        />
      </Layout>
    </>
  )
}

export default Broadcaster;